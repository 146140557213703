import React from "react"
import Link from "gatsby-link"
import logoWhite from "./logo-white.svg"
import instagramIcon from "./instagram-icon.png"
import mailIcon from "./mail-icon.png"

const Footer = () => (
  <div className="footer">
    <footer className="container py-5">
      <div className="row">
        <div className="col-10 brand">
          <img
            src={logoWhite}
            alt="Damoori Kitchen logo"
            width="180"
            height="76"
            className="logo"
          />
        </div>

        <ul className="col list-unstyled">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/featured-menus">Catering</Link>
          </li>
          <li>
            <Link to="/products">Products</Link>
          </li>
          <li>
            <Link to="/events">Events</Link>
          </li>
          <li>
            <Link to="/our-story">Our Story</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li className="social-icons">
            <a
              href="https://www.instagram.com/damoorikitchen/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={instagramIcon}
                alt="Instagram icon"
                width="32"
                height="32"
              />
            </a>
            <a
              href="mailto:hello@damoorikitchen.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={mailIcon} alt="Email icon" width="32" height="24" />
            </a>
          </li>
        </ul>
      </div>
      <small className="d-block mb-3 muted">&copy; {new Date().getFullYear()} Damoori Kitchen</small>
    </footer>
  </div>
)

/*

Submenu example for posterity

<div className="col-6 col-md">
    <ul className="list-unstyled text-small">
        <li><a className="muted" href="#">Kibbeh</a></li>
        <li><a className="muted" href="#">Falafel</a></li>
        <li><a className="muted" href="#">Dips and Spreads</a></li>
        <li><a className="muted" href="#">Dressings</a></li>
    </ul>
</div>
*/

export default Footer
