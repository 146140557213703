import React, { Component } from "react"
import Link from "gatsby-link"

export class NavDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

  handleBlur(e) {
    // HACK
    this.timeoutId = window.setTimeout(() => {
      this.setState({ open: false })
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeoutId) window.clearTimeout(this.timeoutId)
  }

  render() {
    const { open } = this.state
    return (
      <li className="nav-item dropdown" onBlur={this.handleBlur}>
        <a
          className="nav-link dropdown-toggle"
          href="javascript:void(0)"
          id="dropdown01"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.handleClick}
        >
          {this.props.title}
        </a>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdown01"
          style={{ display: open ? "block" : "none" }}
          onClick={this.handleClick}
        >
          {this.props.children}
        </div>
      </li>
    )
  }
}

export const NavDropdownItem = ({ linkTo, label }) => (
  <Link to={linkTo} className="dropdown-item">
    {label}
  </Link>
)
