import React, { Component } from "react"
import Link from "gatsby-link"
import { NavDropdown, NavDropdownItem } from "./navDropdown"
import logo from "./logo.svg"
import { cateringGoogleFormsLink, ezCaterLink } from "../../constants"

export const ComingSoonBadge = ({ style }) => {
  const defaultStyle = {
    backgroundColor: "#7bafd4",
    borderRadius: "0.5rem",
    marginRight: "0.3rem",
    fontSize: "12px",
  }
  return (
    <span
      className="badge badge-success"
      style={Object.assign({}, defaultStyle, style)}
    >
      Coming Soon!
    </span>
  )
}
export const NewBadge = ({ style }) => {
  const defaultStyle = {
    backgroundColor: "#FF5F50",
    borderRadius: "0.5rem",
    marginRight: "0.3rem",
    fontSize: "12px",
  }
  return (
    <span
      className="badge badge-success"
      style={Object.assign({}, defaultStyle, style)}
    >
      New!
    </span>
  )
}

export const NowOpenBadge = ({ style }) => {
  const defaultStyle = {
    backgroundColor: "#FF5F50",
    borderRadius: "0.5rem",
    marginLeft: "0.3rem",
    fontSize: "12px",
  }
  return (
    <span
      className="badge badge-success"
      style={Object.assign({}, defaultStyle, style)}
    >
      Now Open!
    </span>
  )
}

export default class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCollapsedNavbar: false,
    }

    this.handleNavbarTogglerClick = this.handleNavbarTogglerClick.bind(this)
    this.handleNavbarTogglerBlur = this.handleNavbarTogglerBlur.bind(this)
  }

  handleNavbarTogglerClick() {
    this.setState({ showCollapsedNavbar: !this.state.showCollapsedNavbar })
  }

  handleNavbarTogglerBlur() {
    window.setTimeout(() => {
      this.setState({ showCollapsedNavbar: false })
    }, 10)
  }

  render() {
    let navbarClassName = "navbar-collapse collapse"
    if (this.state.showCollapsedNavbar) navbarClassName += "-show"
    return (
      <nav className="navbar navbar-expand-md navbar-light">
        <Link to="/" className="navbar-brand">
          <img
            src={logo}
            alt="Damoori Kitchen logo"
            width="180"
            height="76"
            className="logo"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.handleNavbarTogglerClick}
          onBlur={this.handleNavbarTogglerBlur}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className={navbarClassName} id="navbarsExampleDefault">
          <ul className="navbar-nav mr-auto justify-content-end">
            {/*

                        // Example for posterity

                        <NavDropdown title="Products" isOpen={false}>
                            <NavDropdownItem label="Wholesale Products" linkTo="/products" />
                        </NavDropdown>
                        */}

            <NavDropdown title="Catering" isOpen={false}>
              <NavDropdownItem
                label="Featured Menus"
                linkTo="/featured-menus"
              />
              <a
                className="dropdown-item"
                href={ezCaterLink}
                target="_blank"
                rel="noreferrer"
              >
                Boxed Lunches for Work
              </a>
              <a
                className="dropdown-item"
                href={cateringGoogleFormsLink}
                target="_blank"
                rel="noreferrer"
              >
                Build-A-Menu
              </a>
            </NavDropdown>

            <li className="nav-item active">
              <Link to="/products" className="nav-link">
                Products
              </Link>
            </li>

            <li className="nav-item active">
              <Link to="/events" className="nav-link">
                Events
              </Link>
            </li>

            <li className="nav-item active">
              <Link to="/our-story" className="nav-link">
                Our Story
              </Link>
            </li>

            <li className="nav-item active">
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}
